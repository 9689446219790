.card {
  margin-top: 75px;
  z-index: 1;
  background-color: rgb(210, 238, 255);
  width: 500px;
  height: 700px;
  box-shadow: 6px 0px 12px rgb(96, 194, 255);
  border-radius: 8px;
  display: inline-block;
}

.logoBox {
  padding-left: 10px;
  padding-top: 10px;
  width: 480px;
  height: 250px;
}

.image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.loginTextInputs {
  box-shadow: 6px 0px 12px 3px rgb(96, 194, 255);

  border-radius: 8px;
  background-color: rgb(255, 255, 255);
  width: 200px;
  align-items: center;
  align-self: center;
}

.submission {
  margin-top: 20px;
}

.notification {
  color: rgb(0, 0, 0);
}
