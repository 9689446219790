.greeting {
  display: flex;
  color: whitesmoke;
  margin-top: 20px;
  justify-content: center;
  justify-items: center;
  align-items: center;
  align-content: center;

  height: 100%;
}

.headerbg {
  background-color: #1976d2;
  height: 100px;
  box-shadow: 10px 10px 10px #141414;
  margin-bottom: 30px;
  opacity: 80%;
  position: relative;
}

.logo {
  position: absolute;
  left: 10px;
  height: 80px;
  top: 10px;
  background-color: white;
  padding-left: 5px;
  padding-right: 5px;
  border: 2px solid black;
  border-radius: 15px;
  box-shadow: 6px 0px 6px #141414;
}
.logobg {
  color: white;
}
